import React, { useState, useEffect } from 'react';

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Button,
  useTheme,
  Typography,
  ListItem,
  ListItemText,
  useMediaQuery,
  Box,
  Popover,
} from '@mui/material';

import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { convertDateToYear, getInitials, shareOnSocialMedia, truncateString } from 'app/utils/common-utils/common-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link, useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import LinkedInIcon from 'app/components/icons/login-page-icons/linkedin-icon';
import { fetchBookmarkedPetition } from 'app/shared/reducers/bookmark-activity';
import ShareIcon from 'app/components/icons/activity-card/share-icon';
import GenericModal from '../../modals/generic-modal';
import LinearProgressBar from '../../progress-bar/linear-progress-bar';
import SignPetitionModal from '../../modals/petition-sign-modal/sign-petition-modal';
import { updatePrevItemId } from 'app/shared/reducers/activity-filter';
import AuthorisationModal from '../../authorization-modal/authorization-modal';
import BlueTwitterIcon from 'app/components/icons/login-page-icons/blue-twitter-icon';
import GrayTwitterIcon from 'app/components/icons/login-page-icons/gray-twitter-icon';
import { appConstants } from 'app/utils/data/constants/constants';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import FeatureDialog from '../../alert-dialog/feature-dialog';
import { fetchBanned, fetchBlocked, fetchProfileDetails } from 'app/shared/reducers/profile';
import Moment from 'react-moment';
import { GallerySlide } from 'app/components/ui/activity-details-section/activity-details-card/activity-details-card';
import ImageGrid from 'app/components/ui/grid-layout/ImageGrid';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import toast from 'react-hot-toast';
import { fetchActivityShareToken, fetchPetitionShareToken } from 'app/shared/reducers/activity-home';

interface PetitionCardProps {
  value?: any;
  petition: any;
  fromProfile?: boolean;
  commonParams?: any;
  handleDeletePetition: (id: string) => void;
}

const PetitionCard = ({ value, petition, handleDeletePetition, fromProfile, commonParams }: PetitionCardProps) => {
  const {
    id,
    eventGallery,
    postedByProfileImage,
    postedBy,
    postAsOrganisation,
    organizationId,
    createdAt,
    category,
    title,
    hasPermission,
    caption,
    reported,
    createdBy,
  } = petition;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(400));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const isMdScreen = useMediaQuery(theme.breakpoints.down(992));
  const isNewScreen = useMediaQuery(theme.breakpoints.down(983));
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [socialAnchorEl, setSocialAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [openSignPetitionModal, setOpenSignPetitionModal] = useState(false);
  const [message, setMessage] = React.useState(' ');
  const showAuthModal = useAppSelector(state => state.authentication.showModalLogin);

  const handleSignPetitionModal = async () => {
    if (isAuthenticated) {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
      const res1 = await dispatch(fetchBlocked({ userId: petition?.createdBy }));
      if (res1?.payload) {
        toast.error(<>Action Restricted: You cannot sign this petition.</>);
        return;
      }
      setOpenSignPetitionModal(true);
      setMessage('You have signed because ... ( Optional )');
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
      return;
    }
  };
  useEffect(() => {
    if (!showAuthModal && isAuthenticated) {
      setAuthModalOpen(false);
    }
  }, [showAuthModal]);

  const handleLeavePetitionModal = () => {
    if (isAuthenticated) {
      setOpenSignPetitionModal(true);
      setMessage('You have leaving petition because ... ( Optional )');
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handleReportPetitionModal = () => {
    if (isAuthenticated) {
      setOpenSignPetitionModal(true);
      handleClose();
      setMessage('Please select a reason for reporting the petition.');
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      handleClose();
      setAuthModalOpen(true);
    }
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
    window.location.reload();

    let filter = {
      nearMe: null,
      country: null,
      state: null,
      city: null,
      organizationId: null,
      remoteEvents: false,
      petition: false,
      mySelf: false,
      categoryId: null,
      subCategoryId: null,
      featured: false,
    };

    // dispatch(filterActivities({ filter, eventType, page: 0 }));
  };

  const handleModalClose = async data => {
    if (data) {
      try {
        let filter = {
          nearMe: null,
          country: null,
          state: null,
          city: null,
          organizationId: null,
          remoteEvents: false,
          petition: false,
          mySelf: false,
          categoryId: null,
          subCategoryId: null,
          featured: false,
        };

        // dispatch(filterActivities({ filter, eventType, page: 0 }));
        setOpenSignPetitionModal(false);

        if (fromProfile) {
          if (value === 0) {
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Participated' }));
          } else if (value === 1) {
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Organized' }));
          } else if (value === 2) {
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Moderated' }));
          } else {
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSocialShareClose = () => {
    setSocialAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const socialShare = Boolean(socialAnchorEl);
  const menuItemId = open ? 'simple-popover' : undefined;
  const socialShareId = socialShare ? 'simple-popover-social' : undefined;
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleButtonClick = (title, id) => {
    const encodedTitle = title.replace(/ /g, '-').toLowerCase();
    navigate(`/petition/${encodedTitle}-${id}`);
    dispatch(updatePrevItemId(id));
  };

  const handleImageError = event => {
    event.target.src = 'content/images/no-img.jpg';
  };

  const handleYesClick = () => {
    handleDeletePetition(id);
    handleClose();
    setConfirmDelete(false);
  };

  const isDarkMode = theme.palette.mode === 'dark';

  const modalActionButtons = [
    { label: 'Yes', onClick: handleYesClick },
    { label: 'No', onClick: () => setConfirmDelete(false) },
  ];

  const postedByStyle = {
    font: 'normal normal 600 16px/22px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : 'secondary.main',
  };

  const petitionCountStyle = {
    font: 'normal normal 600 14px/23px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : '#292D34',
  };

  const handleShareButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSocialAnchorEl(event.currentTarget);
  };

  const handleBookmarkClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
      return;
    }

    try {
      const actionResult = await dispatch(fetchBookmarkedPetition(Number(id)));

      if (fetchBookmarkedPetition.fulfilled.match(actionResult)) {
        setIsBookmarked(prevState => !prevState);
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      } else {
        console.error('Bookmarking was not successful:', actionResult.error?.message || 'An unknown error occurred');
      }
    } catch (error) {
      console.error('Bookmarking failed:', error);
    }
  };

  const sentParameters = async data => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    } else {
      const { id, eventType } = data;
      const storageData = { id, eventType };
      if (localStorage.getItem('dataKey')) {
        localStorage.removeItem('dataKey');
      }
      localStorage.setItem('dataKey', JSON.stringify(storageData));
      navigate('/chat');
    }
  };

  useEffect(() => {
    if (petition?.bookMark !== undefined) {
      setIsBookmarked(petition.bookMark);
    }
  }, [petition.bookMark]);

  const [confirmFeature, setConfirmFeature] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>();
  const [passId, setPassId] = React.useState<any>();

  const handleFeatureClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setPassId(data?.id);
    handleGetCatalogue(3);
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async () => {
    setPaymentLoading(true);

    let passData = {
      eventId: passId,
      eventType: 3,
      quantity: 1,
      productId: payload?.productId,
    };
    localStorage.setItem('passEventId', passData?.eventId);
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));
    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(false);
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        console.log('check res', response);
        setPayload(response.data);
        setConfirmFeature(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);

  const slides: GallerySlide[] = eventGallery?.map(img => {
    return {
      type: 'image',
      src: img?.link,
      thumbnail: img?.link,
      title: '',
    };
  });

  const getShareToken = (socialName, content, activityId, type, URL, shareMessage) => {
    console.log(socialName, content, activityId, type, URL, shareMessage, 'socialName, URL, shareMessage');

    dispatch(fetchPetitionShareToken({ activityId, type }))
      .then(result => {
        const token = result.payload.token;

        if (token) {
          const utmSource = socialName;
          const utmCampaign = 'Reachamy';
          const utmContent = content.replace(/\s/g, '');

          const newURL = `${URL}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_content=${utmContent}&t=${token}`;

          shareOnSocialMedia(socialName, newURL, shareMessage);
        }
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        toast.error('Something went wrong');
      });
  };

  return (
    <Card
      key={id}
      id={id}
      sx={{ minWidth: '100% ', backgroundColor: 'secondary.dark', boxShadow: 'none', borderRadius: '25px', pb: 1 }}
      onError={handleImageError}
    >
      <>
        <div style={{ height: '430px', position: 'relative', borderRadius: '15px', overflow: 'hidden' }}>
          {petition?.featured && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                background: '#674BFF',
                color: '#FFFF',
                padding: '5px 10px',
                borderRadius: '0 0 15px 0',
                zIndex: '999',
                fontSize: '12px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Featured</span>
            </div>
          )}
          <ImageGrid onImageClick={handleLightBoxClick}>
            {eventGallery?.map((item, index) => (
              <div
                key={item.id || item.link || index}
                className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}
              >
                <img
                  alt="ig"
                  src={item.type === 'videos' ? item?.thumbnail : item?.link}
                  style={{ objectPosition: '1px 1%', objectFit: 'cover' }}
                />
              </div>
            ))}
          </ImageGrid>

          <div
            className="legend d-flex justify-content-between align-items-baseline"
            style={{
              position: 'absolute',
              height: '40px',
              background: 'rgba(0, 0, 0, 0.3)',
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <div>
              <span className="d-flex  align-items-baseline ">
                {petition?.eventParticipantsCount > 0 && (
                  <>
                    <Avatar src="slide1.jpg" style={{ height: '20px', width: '20px', zIndex: '2' }} />
                    <Avatar src="slide1.jpg" style={{ height: '20px', width: '20px', zIndex: '1', marginLeft: '-6px' }} />

                    <p style={{ color: '#ffff', marginRight: '10px', marginLeft: '10px', fontSize: '11px' }}>
                      {petition?.eventParticipantsCount}
                    </p>

                    <Box
                      sx={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '50%',
                        backgroundColor: '#ffff',
                      }}
                    />
                  </>
                )}

                {petition?.ageLimit !== null && (
                  <>
                    <p style={{ color: '#ffff', marginLeft: '10px', fontSize: '11px' }}>{`age limit ${petition?.ageLimit}+`}</p>
                  </>
                )}
              </span>
            </div>

            <div>
              {isBookmarked ? (
                <BookmarkIcon
                  sx={{
                    color: '#674BFF',
                    fontSize: '24px',
                    marginRight: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={handleBookmarkClick}
                />
              ) : (
                <BookmarkBorderIcon
                  sx={{
                    color: '#ffff',
                    fontSize: '24px',
                    marginRight: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={handleBookmarkClick}
                />
              )}
            </div>
          </div>
        </div>
        <Lightbox
          carousel={{ finite: true }}
          open={index >= 0}
          close={handleLightBoxClose}
          slides={slides}
          plugins={[Video]}
          index={index}
        />
      </>

      <div
        style={{
          paddingLeft: isSmallerScreen ? '8px' : '16px',
          paddingRight: isSmallerScreen ? '8px' : '16px',
          paddingTop: isSmallerScreen ? '8px' : '16px',
          paddingBottom: isSmallerScreen ? '8px' : '16px',
        }}
      >
        <div className="row d-flex justify-content-between ">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            // onClick={() => {
            //   handleButtonClick(title, id);
            // }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap={1}
              marginBottom="14px"
              width="30vw"
              // onClick={() => {
              //   handleButtonClick(title, id);
              // }}
            >
              <Avatar
               onClick={() => {
                const formattedPostedBy = postedBy?.replace(/\s+/g, '-') || ''; 
                postAsOrganisation
                  ? navigate(`/organization/${formattedPostedBy}-${organizationId}`)
                  : navigate(`/profile/${formattedPostedBy}-${createdBy}`);
              }}
                alt={postedByProfileImage ? '' : getInitials(postedBy)}
                src={postedByProfileImage ? postedByProfileImage : ''}
                sx={{ width: '30px', height: '29px', cursor: 'pointer', fontSize: postedByProfileImage ? 'default' : '12px' }}
              >
                {' '}
                {getInitials(postedBy)}
              </Avatar>
              <Box display="flex" gap={1.6} alignItems="center">
                <span
                  // onClick={() => {
                  //   handleButtonClick(title, id);
                  // }}
                >
                  <Typography variant="body1" display="flex" gap={1} alignItems="center">
                    <Link style={{ fontWeight: 600 }} to={postAsOrganisation ? `/organization/${postedBy?.replace(/\s+/g, '-')}-${organizationId}` : `/profile/${postedBy?.replace(/\s+/g, '-')}-${createdBy}`}>
                      {isSmallDevice && postedBy?.length > 36 ? truncateString(postedBy, 15) : postedBy}
                      <Box ml={0.6} display="inline-flex" component="span" gap={0.5} alignItems="center">
                        <Typography
                          component="span"
                          className="text-lowercase"
                          sx={{ font: 'normal normal normal 13px/21px Manrope', color: '#9FA1A4' }}
                        >
                          {/* {timestampToHoursAgo(createdAt)} */}
                          <Moment fromNow>{createdAt}</Moment>
                        </Typography>
                      </Box>
                    </Link>
                  </Typography>
                </span>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              onClick={() => {
                handleButtonClick(title, id);
              }}
            >
              {Number(petition?.petitionCount) === Number(petition?.petitionLimit) ? (
                <>
                  {' '}
                  <div className={`text-end d-flex justify-content-end align-items-center`}>
                    <div style={{ width: '100%' }} className="text-center text-lowercase">
                      <span style={{ ...petitionCountStyle }}>{`${petition?.petitionCount} signed of ${petition?.petitionLimit}`}</span>
                      <LinearProgressBar
                        currentValue={parseFloat(petition?.petitionCount)}
                        maxValue={parseFloat(petition?.petitionLimit)}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className={`text-end d-flex justify-content-end align-items-center`}>
                  <div style={{ width: '100%' }} className="text-center text-lowercase">
                    <span style={{ ...petitionCountStyle }}>{`${petition?.petitionCount} signed of ${petition?.petitionLimit}`}</span>
                    <LinearProgressBar currentValue={parseFloat(petition?.petitionCount)} maxValue={parseFloat(petition?.petitionLimit)} />
                  </div>
                </div>
              )}

              {/* {Number(petition?.petitionCount) !== Number(petition?.petitionLimit) && (
                <div style={{ width: '100%' }} className="text-center text-lowercase">
                  <LinearProgressBar currentValue={parseFloat(petition?.petitionCount)} maxValue={parseFloat(petition?.petitionLimit)} />
                </div>
              )} */}

              {Number(petition?.petitionCount) === Number(petition?.petitionLimit) ? (
                <div className="text-end"></div>
              ) : (
                <div className="text-end">
                  <p className="mt-1 mb-0" style={{ font: 'normal normal normal 13px/21px Manrope', color: '#9FA1A4' }}>
                    {petition?.petitionCountToday === 1
                      ? `${petition.petitionCountToday} person signed today`
                      : `${petition.petitionCountToday} people signed today`}
                  </p>
                </div>
              )}
            </Box>
          </Box>

          <Box display="flex" alignItems="center" gap="6px" flexWrap="wrap">
            <Button
              className="mb-2"
              sx={{
                fontSize: '13px',

                borderRadius: '20px',
                height: '28px',
                whiteSpace: 'nowrap',
                backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                cursor: 'auto',
              }}
              onClick={() => {
                handleButtonClick(title, id);
              }}
            >
              {category?.category}
            </Button>

            {petition?.makeAsUrgentNeed && petition?.category?.id !== 19 && (
              <Button
                variant="contained"
                className="mb-2 me-1"
                sx={{
                  fontSize: '13px',
                  borderRadius: '20px',
                  height: '28px',
                  whiteSpace: 'nowrap',
                  backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                  color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                  boxShadow: 'none',
                  cursor: 'auto',
                }}
              >
                Urgent
              </Button>
            )}

            {/* </span> */}
          </Box>

          <div className="col-12 d-flex justify-content-between" style={{ marginTop: isMdScreen ? 'unset' : '3px' }}>
            <p className="col-8" style={{ font: 'normal normal normal 14px/21px Manrope', color: '#9FA1A4' }}>
              <b> Petition to &nbsp;{petition?.petitionTo?.join(', ')}</b>
            </p>

            <p className="col-4 text-end" style={{ font: 'normal normal normal 14px/21px Manrope', color: '#9FA1A4' }}>
              <b>
                Start date <br></br>
                {convertDateToYear(petition?.startDate)}
              </b>
            </p>
          </div>

          <CardContent style={{ marginTop: isMdScreen ? '-18px' : '-12px', padding: '0px 13px 0px 13px' }}>
            <div
              style={{
                font: ' normal normal 600 24px/32px Manrope',
                textAlign: 'left',
                letterSpacing: 0,
                cursor: 'pointer',
              }}
              onClick={() => {
                handleButtonClick(title, id);
              }}
            >
              {title}
            </div>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                color: theme.palette.secondary.light,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '6em',
                lineClamp: 3,
                WebkitLineClamp: 3,
              }}
              onClick={() => handleButtonClick(title, id)}
            >
              {caption ? (
                <>
                  <span
                    style={{
                      color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#5A5F67',
                      cursor: 'pointer',
                    }}
                  >
                    {caption}
                  </span>

                  {caption.length > 220 && <span style={{ cursor: 'pointer' }}>... Read more</span>}
                </>
              ) : null}
            </Typography>
          </CardContent>

          <CardActions sx={{ marginBottom: '-43px' }}>
            <div className="row" style={{ width: '105%' }}>
              <div className="col-4" onClick={e => e.stopPropagation()}>
                <div className=" d-flex">
                  <span className=" d-flex mt-3 mb-3 ms-2 me-2" onClick={() => sentParameters(petition)}>
                    <SquareChatIcon style={{ cursor: 'pointer', fontSize: '19px' }} />
                    <p className="px-1 px-md-2" style={{ fontSize: '14px' }}>
                      {petition?.chatMsgCount}
                    </p>
                  </span>

                  <span className=" d-flex mt-3 mb-3 ms-2 me-2" onClick={handleShareButtonClick}>
                    <ShareIcon aria-describedby={socialShareId} style={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
                    <p className="px-md-2 px-1"></p>
                  </span>

                  <Popover
                    id={socialShareId}
                    open={Boolean(socialAnchorEl)}
                    anchorEl={socialAnchorEl}
                    onClose={handleSocialShareClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                      {theme.palette.mode === 'dark' ? (
                        <BlueTwitterIcon
                          sx={{ cursor: 'pointer' }}
                          // onClick={() => shareOnSocialMedia('twitter', petition?.eventURL, appConstants.SocialShareMessage)}
                          onClick={() =>
                            getShareToken(
                              'twitter',
                              petition?.title.slice(0, 10),
                              petition?.id,
                              1,
                              petition?.eventURL,
                              appConstants.SocialShareMessage
                            )
                          }
                        />
                      ) : (
                        <GrayTwitterIcon
                          sx={{ cursor: 'pointer' }}
                          // onClick={() => shareOnSocialMedia('twitter', petition?.eventURL, appConstants.SocialShareMessage)}
                          onClick={() =>
                            getShareToken(
                              'twitter',
                              petition?.title.slice(0, 10),
                              petition?.id,
                              1,
                              petition?.eventURL,
                              appConstants.SocialShareMessage
                            )
                          }
                        />
                      )}
                    </ListItem>

                    <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                      <FacebookIcon
                        sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                        // onClick={() => shareOnSocialMedia('facebook', petition?.eventURL, appConstants.SocialShareMessage)}
                        onClick={() =>
                          getShareToken(
                            'facebook',
                            petition?.title.slice(0, 10),
                            petition?.id,
                            2,
                            petition?.eventURL,
                            appConstants.SocialShareMessage
                          )
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                      <LinkedInIcon
                        sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                        // onClick={() => shareOnSocialMedia('linkedIn', petition?.eventURL, appConstants.SocialShareMessage)}
                        onClick={() =>
                          getShareToken(
                            'linkedIn',
                            petition?.title.slice(0, 10),
                            petition?.id,
                            3,
                            petition?.eventURL,
                            appConstants.SocialShareMessage
                          )
                        }
                      />
                    </ListItem>
                  </Popover>

                  {isAuthenticated && (
                    <span className=" d-flex ms-0 mt-3 mb-3 me-4" onClick={handleClick} style={{ cursor: 'pointer' }}>
                      <MoreHorizOutlinedIcon aria-describedby={menuItemId} sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
                    </span>
                  )}
                  {hasPermission === true && (
                    <Popover
                      id={menuItemId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <ListItem
                        sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit', padding: '8px 16px' }}
                      >
                        {hasPermission === true && (
                          <>
                            {/* edit petition logic */}

                            {petition?.petitionStatus !== 'COMPLETED' && (
                              <ListItemText sx={{ padding: 0 }}>
                                <Link to={`/edit-petition/${id}`} className="text-decoration-none">
                                  <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}> Edit petition</Typography>
                                </Link>
                              </ListItemText>
                            )}

                            {/* delete petition logic  */}
                            {petition?.petitionCount <= 1 && (
                              <ListItemText
                                sx={{ padding: 0 }}
                                onClick={() => {
                                  handleClose();
                                  setConfirmDelete(true);
                                }}
                              >
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}> Delete petition</Typography>
                              </ListItemText>
                            )}

                            {petition?.hasPermission === true && (
                              <button
                                style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                  handleFeatureClick(event, petition);
                                }}
                              >
                                <ListItemText sx={{ padding: 0 }} className="text-start">
                                  <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                    Feature petition
                                  </Typography>
                                </ListItemText>
                              </button>
                            )}
                          </>
                        )}
                      </ListItem>
                    </Popover>
                  )}

                  {!hasPermission && (
                    <Popover
                      id={menuItemId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <ListItem
                        sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit', padding: '8px 16px' }}
                      >
                        {petition?.participant && !hasPermission && Number(petition?.petitionCount) !== Number(petition?.petitionLimit) && (
                          <ListItemText sx={{ padding: 0 }} onClick={handleLeavePetitionModal}>
                            <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Leave petition</Typography>
                          </ListItemText>
                        )}

                        {petition?.permittedToFeatured === true && (
                          <button
                            style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              handleFeatureClick(event, petition);
                            }}
                          >
                            <ListItemText sx={{ padding: 0 }} className="text-start">
                              <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                Feature petition
                              </Typography>
                            </ListItemText>
                          </button>
                        )}

                        {/* Report option logic */}

                        {!hasPermission && !reported ? (
                          <ListItemText sx={{ padding: 0 }} onClick={handleReportPetitionModal}>
                            <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Report petition</Typography>
                          </ListItemText>
                        ) : !hasPermission && reported ? (
                          <ListItemText sx={{ padding: 0 }}>
                            <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Reported</Typography>
                          </ListItemText>
                        ) : null}
                      </ListItem>
                    </Popover>
                  )}
                </div>
              </div>{' '}
              {Number(petition?.petitionCount) === Number(petition?.petitionLimit) ? (
                <div className="col-8 p-3   d-flex justify-content-end">
                  <p
                    style={{
                      color: theme.palette.mode === 'dark' ? '#FFCE00' : '#674BFF',
                      font: 'normal normal 600 13px/21px Manrope',
                      cursor: 'default',
                    }}
                  >
                    Successful!
                  </p>
                </div>
              ) : (
                <div className="col-8 p-2  d-flex justify-content-end">
                  {petition?.timelineCategory !== 'COMPLETED' && (
                    <>
                      {petition?.petitionStatus === 'COMPLETED' && (
                        <Button
                          variant="contained"
                          className="me-1"
                          sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            backgroundColor: 'transparent',
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                          }}
                        >
                          Completed
                        </Button>
                      )}

                      {!petition?.participant && petition?.petitionStatus !== 'COMPLETED' ? (
                        <Button
                          variant="contained"
                          className="me-1"
                          sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              color: 'common.black',
                            },
                          }}
                          onClick={handleSignPetitionModal}
                        >
                          Sign this petition
                        </Button>
                      ) : petition?.petitionStatus !== 'COMPLETED' ? (
                        <p
                          className="me-1"
                          style={{
                            color: theme.palette.mode === 'dark' ? '#FFCE00' : theme.palette.primary.light,
                            font: 'normal normal 600 13px/21px Manrope',
                          }}
                        >
                          You have already signed
                        </p>
                      ) : (
                        ''
                      )}
                    </>
                  )}

                  {petition?.timelineCategory === 'COMPLETED' && (
                    <Button
                      variant="contained"
                      className="me-1"
                      sx={{
                        fontSize: '14px',
                        borderRadius: '20px',
                        height: '28px',
                        lineHeight: '12px',
                        backgroundColor: 'transparent',
                        color: '#674BFF',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: '#674BFF',
                        },
                      }}
                    >
                      Completed
                    </Button>
                  )}
                </div>
              )}
            </div>
          </CardActions>

          <SignPetitionModal
            fullScreen={fullScreen}
            id={petition?.id}
            message={message}
            onClose={handleModalClose}
            open={openSignPetitionModal}
          />

          <GenericModal
            handleClose={() => setConfirmDelete(false)}
            fullScreen={fullScreen}
            open={confirmDelete}
            title="Are you sure want to delete?"
            buttons={modalActionButtons}
          />

          <FeatureDialog
            paymentLoading={paymentLoading}
            agreeAction={handlePayment}
            values={payload}
            open={confirmFeature}
            setFeatureOpen={setConfirmFeature}
          />
        </div>
      </div>

      {/* login modal */}

      <AuthorisationModal
        currentComponent={currentComponent}
        fullScreen={fullScreen}
        authModalOpen={authModalOpen}
        onClose={handleAuthModalClose}
        handleAuthModalClose={handleAuthModalClose}
        setCurrentComponent={setCurrentComponent}
      />
    </Card>
  );
};

export default PetitionCard;
